body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.tabBar {
  width: calc(100% - 20px);
  /* background-color: #00000099; */
  padding: 10px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.tabButton {
  background-color: white;
  /* width: 100px; */
  text-align: center;
  padding: 10px;
  color: #000000;
  /* margin-right: 20px; */
  /* border: 1px solid #000000; */
}

.tabButtonActive {
  background-color: white;
  /* width: 100px; */
  text-align: center;
  padding: 10px;
  font-weight: bold;
  color: #004d72;
  /* margin-right: 20px; */
}


h1,
input,
textarea {
  font-family: 'Rubik', Sans-Serif;
}

input,
textarea {
  width: 100%;
  padding: 14px 20px;
  box-sizing: border-box;
  margin-top: 10px;
  font-size: 15px;
  border: 1px solid #DCDCDC;
  border-radius: 4px;
}

input[type='checkbox'],
input[type='radio'] {
  width: max-content;
}

textarea {
  height: 200px;
}

button {
  background-color: #004d72;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 20px;
  border-radius: 6px;
}

.secondaryButton {
  background-color: #FFFFFF;
  border: 1px solid #000000;
  color: #000000;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 20px;
  border-radius: 6px;
}

button:hover,
.secondaryButton:hover {
  cursor: pointer;
}


button:disabled,
.secondaryButton:disabled {
  opacity: 0.5;
}

.react-datepicker__input-container input {
  border: 1px solid hsl(0, 0%, 70%);
  border-radius: 5px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.flexWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

tbody {
  width: 100%;
}


td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd30;
}

.hover:hover {
  cursor: pointer;
}


/* Start Form */
.form-wrapper {
  width: calc(100% - 20px);
  padding: 10px;
  max-width: 700px;
  margin: auto;
}

.flexrow {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.flexrow>div {
  width: 45%;
}

.fieldHeading {
  font-weight: bold;
  margin: 20px 0 10px;
  display: flex;
  font-size: 15px;
}



.basic-single {
  width: 100%;
  margin-top: 10px;
}

.select__control {
  padding: 4px;
}

.basic-single-error {
  width: 100%;
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid red;
}

.error {
  border: 1px solid red !important;
}

.errorImage>div {
  border-color: red !important;
}

hr {
  margin-top: 40px;
  color: #dcdcdc;
}

.sigCanvas {
  border: 1px solid #d3d3d3d3;
  border-radius: 4px;
}


.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox>input {
  margin: 0;
  width: 35px;
  transform: scale(1.4);
  /* margin-bottom: 10px; */
}

/* End Form */



/* Dashboard */

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sidebarList {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: calc(100vh - 200px);
  overflow: scroll;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
}

.sidebarRow {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dcdcdc;
  padding: 10px;
}

.nameTimeBlock {
  display: flex;
  justify-content: space-between;
}

.nameBlock {
  display: flex;
  /* width: calc(100% - 100px); */
  flex-wrap: wrap;
}

.nameBlock>label {
  font-size: 15px;
}

.timeBlock {
  width: 80px;
  text-align: right;
}


.contentBlock {
  display: flex;
  flex-direction: column;
  width: calc(100% - 320px);
  height: calc(100vh - 200px);
  overflow: scroll;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
}

.formWrapperInContentBlock {
  max-width: 900px;
  margin: auto;
  width: 100%;
}


.textButton {
  background-color: white;
  color: #000000;
  padding: 0;
  display: flex;
  margin: 10px;
  text-decoration: underline;
}

.activeRow {
  background-color: #FF710040;
}

.completedRow {
  background-color: #ffe317;
}

.verifiedRow {
  background-color: #4cd038;
}

.radioGroup,
.checkboxGroup {
  flex-direction: column;
}

.radioGroup>div {
  width: max-content;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: black;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content label {
  padding: 10px;
}

.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
}

/* End Dashboard */

.hamburger {
  display: none;
}

.show-mobile {
  display: none;
}

.mobile-menu {
  display: none;
  width: 100%;
  position: absolute;
  top: 90px;
  left: 10px;
  background-color: white;
  width: calc(100% - 40px);
  border: 1px solid grey;
  padding: 10px;
  z-index: 100;
}

/* Start Precinct */



/* End Precinct */


.navLinks {
  display: flex;
  gap: 20px;
}

.navLinks div {
  border-bottom: 0 !important;
}

.preview {
  max-height: 60vh;
  overflow: scroll;
}

.preview>div {
  display: flex;
  margin: 5px auto;
}

.preview>div>label:nth-child(1) {
  font-weight: bold;
}

.preview>div>label:nth-child(2) {
  margin-left: 5px;
}


.detailedPreview {
  width: 100%;
  /* overflow: hidden; */
}

.detailedPreview>div {
  /* display: flex;
  margin: 5px auto; */
  margin-bottom: 5px;
}

.detailedPreview>div>label:nth-child(1) {
  font-weight: bold;
}

.detailedPreview>div>label:nth-child(2) {
  margin-left: 5px;
  word-wrap: break-word;
}


table tbody {
  display: block;
  /* max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden; */
}

table thead,
table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* table scrollable */
.scroll-table tbody {
  display: block;
  /* max-height: 600px; */
  overflow-y: scroll;
  overflow-x: hidden;
}

.scroll-table thead,
.scroll-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.page-break {
  display: flex;
  flex-direction: column;
  page-break-before: always;
}

.apexcharts-text tspan {
  font-family: arial, sans-serif !important;
  color: black;
}

.apexcharts-toolbar{
  display: none !important;
}

@media screen and (max-width: 700px) {
  .hide-mobile {
    display: none;

  }

  .scroll-table tr {
    width: 100%;
    display: flex;
  }


  .preview>div {
    display: flex;
    margin: 0;
    padding: 5px auto;
  }

  .preview>div>label {
    width: 50%;
  }

  .preview>div>label:nth-child(2) {
    margin-left: 0px;
  }


  .mobile-menu,
  .show-mobile {
    display: flex;
    flex-direction: column;
  }

  .show-mobile th,
  .show-mobile td {
    width: 50%;
  }

  .flexrow {
    flex-direction: column;
  }

  .flexrow>div {
    width: 100%;
  }

  .header .logo {
    width: 140px !important;
  }
}

@media screen and (max-width: 400px) {
  .flip-vertical-table tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px !important;
  }

  .flip-vertical-table td {
    width: 95% !important;
  }

  .header .logo {
    width: 100px !important;
  }
}

@media print {
  .hide-print {
    display: none;
  }


}